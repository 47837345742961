import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'gatsby';

import styles from './search-results.module.scss';

const ResultsList = ({ results }) => (
  <ul className={styles.list}>
    {results.map(result => (
      <li key={result.id} className={styles.result}>
        <h2 className={styles.title}>
          <Link to={result.path}>
            {result.title}
          </Link>
        </h2>
        <p className={styles.description}>{result.description}</p>
      </li>
    ))}
  </ul>
);

const SearchResults = ({ searchResults, searchQuery }) => (
  <section className={styles.wrapper} aria-label="Search results for all posts">
    {searchResults.length
      ? <ResultsList results={searchResults} />
      : <h2>No results found for search key: "{searchQuery}"</h2>
    }
  </section>
);

const mapStateToProps = ({search: {searchQuery, searchResults}}) => {
  return {
    searchQuery,
    searchResults,
  }
};

export default connect(mapStateToProps)(SearchResults);
